.subCard {
    margin-bottom: 15px;
    margin-top: 15px;
}

.cardButtonGroupSub {
    text-align: center;
    padding-bottom: 10px;
}

.cardButtonGroup {
    text-align: right;
    padding-bottom: 10px;
}

.cardButtonGroup > .rightButton {
    padding-left: 5px;
    padding-right: 10px;
}

.cardButtonGroup > .leftButton {
    padding-left: 10px;
    padding-right: 5px;
}

.subAlert {
    min-height: 10vh;
    margin-top: 20px;
}

.link {
    font-weight: bold;
}

.link :hover {
    cursor: pointer;
    text-decoration: underline;
}

.settings {
    color: #474747;
    position: absolute;
    top: 10px;
    right: 10px;
}

.settings :hover {
    cursor: pointer;
    color: white;
}

.adminTitle {
    padding: 20px 0px 15px 0px;
    
}

.hideOverflow {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}