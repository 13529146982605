.product-image {
    display: flex;
    align-items: center;
}

.product-image > div {
    margin: 5px 0px 20px 0px;
}

.image {
    vertical-align: middle;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 2%;
}

.details {
    padding: 1px;
}

.product {
    padding: 10px 0px 25px 0px;
}

.highlights {
    padding: 15px;
}

.highlights > div {
    padding: 35px 0px 50px 0px;
}

.highlights > div > div {
    text-align: justify;
    text-justify: inter-word;
    white-space: pre-wrap;
    padding-top: 10px;
}

.highlightTitle {
    padding: 15px 0px 15px 0px;
}

.price {
    padding: 2px 0px 5px 0px;
}

.LA {
    font-size: smaller;
    margin-top: 5%;
}