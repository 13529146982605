.customForm {
    padding: 25px;
    background-color: rgb(248, 249, 250);
    border-radius: 5px;
}

.verifyForm {
    padding-top: 50px;
    padding-bottom: 50px;
}

.spacing {
    padding-top: 5%;
    padding-bottom: 20px;
}

.registerBackground {
    background: url('https://assets.virbela.com/opencampus/newcampus.jpg') rgba(0,0,0, 0.20);
    background-size: cover;
    background-blend-mode: multiply;
}

.LA :hover{
    cursor: pointer;
}

.rowFormat {
    padding: 15px 0px 15px 0px;
}

.page-title-row {
    padding-bottom: 0;
}

.form-instructions {
    padding-bottom: 15px;
    font-style: italic;
}

.info-box {
    background: rgba(25,25,25,0.9);
    padding: 55px 15px 55px 15px;
    border-radius: 10px;
    min-width: 100%;
}

.info-box > .left-col {
    position: relative;
    min-height: 250px;
}

.left-col-center {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: white;
}

#verify-image {
    margin-top: 30px;
    margin-bottom: 15px;
}

.download-buttons {
    margin: 10px;
}

#mac-download,
#pc-download {
    margin: 10px;
    border-radius: 25px;
}

.verify-success p,
.verify-failure p {
    font-size: 14px;
}

.verify-success-header {
    border: 3px solid #1BC000;
    border-radius: 6px;
    padding: 20px;
}

.verify-failure-header {
    border: 3px solid #eb1d45;
    border-radius: 6px;
    padding: 20px;
}

.verify-success-title,
.verify-failure-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.verify-success-title h6,
.verify-failure-title h6 {
    margin: 0 0.5rem;
}

.verify-success-subtitle,
.verify-failure-subtitle {
    margin: 0;
    margin-top: 1rem;
}

.verify-success-section,
.verify-failure-section {
    margin-top: 3rem;
}

.verify-success-section h6,
.verify-failure-section h6 {
    margin-top: 1.25rem;
}

.input-invalid, .LA.input-invalid > label::before {
    border-color: orangered !important;
}

.field-invalid-message {
    color: orangered;
    font-size: smaller;
}

#password-reqs-list > * {
    padding: 0;
    font-size: smaller;
}

.password-req-valid {
    color: green;
}

.password-req-invalid {
    color: #AAA;
}

#password-reqs-list.password-invalid > .password-req-invalid {
    color: orangered;
}
