.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: #1d1d1d
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#consent_blackbar {
  position: fixed;
  bottom: 0;
  width: 100%;
}

#teconsent {
  display: none !important;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.minHeightHeader {
  min-height: 75vh;
}

.minHeightNoHeader {
  min-height: 95vh;
}

.hover :hover {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}

p {
  font-family: 'Open Sans', sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body:not(.modal-open){
  padding-right: 0px !important;
}

button.bttn {
  color: #1d1d1d;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  padding-top: 11px;
  padding-right: 24px;
  padding-bottom: 11px;
  padding-left: 24px;
  height: 48px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 2%;
  background-color: #d2d8da;
}

button.block {
  display: block;
  width: 100%;
}

button.outline {
  background-color: rgba(0,0,0,0);
  border: solid;
  border-width: thin;
  border-color: #d2d8da;
  color:#1d1d1d;
}

button.bttn:hover {
  background-color: #bac6ca;
  color: #1d1d1d;
  border: none;
}

button.primary {
  background-color: #25afe3;
}

button.outline.primary {
  background-color: rgba(0,0,0,0);
  border: solid;
  border-width: thin;
  border-color: #25afe3;
  color:#25afe3;
}

button.primary:hover {
  background-color: #21a2d1;
  color: #1d1d1d;
  border: none;
}

button.secondary {
  background-color: #36ae4a;
}

button.outline.secondary {
  background-color: rgba(0,0,0,0);
  border: solid;
  border-width: thin;
  border-color: #36ae4a;
  color:#36ae4a;
}

button.secondary:hover {
  background-color: #309c42;
  color: #1d1d1d;
  border: none;
}

button.danger {
  background-color: #e0284d;
  color: #fff;
}

button.outline.danger {
  background-color: rgba(0,0,0,0);
  border: solid;
  border-width: thin;
  border-color: #e0284d;
  color:#e0284d;
}

button.danger:hover {
  background-color: #d12648;
  color: #fff;
  border: none;
}

.containerBg {
  background: #F9F9F9;
  padding: 25px;
   border-radius: 2%;
}

.hoverText {
  color: rgb(104, 103, 103);
}

.hoverText :hover {
  color: black;
  cursor: pointer;
}

.pageCenter {
  padding: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left:50%;
  width: 98%;
}

.pageDefaultBg {
  background: url('https://assets.virbela.com/opencampus/newcampus.jpg') rgba(0,0,0, 0.20);
  background-size: cover;
  background-blend-mode: multiply;
}

.divider {
  border: none;
  border-top: 1px solid rgb(197, 194, 194);
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
  width: 95%;
}

.divider:after {
  background: #fff;
  color: rgb(139, 132, 132);
  content: 'OR';
  padding: 0 4px;
  position: relative;
  top: -13px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.3);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.shift-icon {
  transform: translateY(-3px);
}

.dropdown-menu .dropdown-item:hover {
  background-color: #2781FF;
  color: white;
}

.link {
  color: #2781FF;
  cursor: pointer;
  text-decoration: underline;
}