.selected {
    font-weight: bold;
}

.passcodeDisplay .passcode {
    display: none;
}

.passcodeDisplay:hover {
    cursor: pointer;
}

.passcodeDisplay:hover .hidden{
    display: none;
}

.passcodeDisplay:hover .passcode {
    display: inline;
    cursor: pointer;
}

.input {
    padding-bottom: 5px;
}

.passcodeForm > div {
    padding-bottom: 5px;
}