.loading {
    margin: 20px;
}

.loading-large {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95vh;
    margin: 0;
    background-size: cover;
    background-position-x: center;
    background-position-y: 0%;
    background-image: url(https://assets.virbela.com/opencampus/Tech_Room_Expansion_banner.png);
}
