.paymentMethod {
    margin: 6px;
}

.notDefault :hover {
    cursor: pointer;
    color: green;
}

.table {
    margin: 10px 0px 10px 0px;
}

.table-past {
    min-height: 500px;
}