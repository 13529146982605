.background {
    background-color: #f8f9fa;
}

.header {
    background-size: cover;
    background-position-x: center;
    background-position-y: 0%;
    background-image: url(https://assets.virbela.com/opencampus/Tech_Room_Expansion_banner.png);
    min-height: 95vh;
}
.columns {
    background-color: white;
    min-height: 30rem;
    background-size: cover;
}

.textColumns {
    transform: translate(0, 8rem);
}

.cards {
    margin-top: 20px;
    font-size: 100%;
    margin-left: 25px;
    margin-right: 20px;
}

li {
    padding: 5px
}

.cardButton {
    margin: 25px
}

.title {
    color: #1d1d1d;
    font-size: 190%;
    padding-top: 14%;
}

.subtitle {
    color: rgb(2, 30, 116);
    font-weight: bold;
    font-size: 125%;
    padding-top: 0%;
    padding-bottom: 10%;
}

.subText {
    color: rgb(56, 83, 163);
    font-size: 95%;
    padding-bottom: 5%;
}

.cards hr{
    padding-top: 5%;
}
