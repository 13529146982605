.insideStyling {
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 98%;
    color: white;
}

.info > div {
    padding-bottom: 20px;
}

.feature {
    position: relative;
    padding: 7vh 5vh 7vh 5vh;
}

.feature > .image {
    height: 35vh;
    padding: 5px;
    margin-bottom: 35px;
}

.feature > .text {
    min-height: 35vh;
}

.feature > .image img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}

.productNav {
    padding: 15px 10px 15px 10px;
    overflow: auto;
    white-space: nowrap;
}

.parallax {
    min-height: 45vh;
    background: rgba(0,0,0,0.6);
    position: relative;
}

.getStarted {
    padding: 0 5vh 7vh 5vh;
}

.productsBg {
    background-image: linear-gradient(to bottom right, rgba(18,126,252,0.5), rgba(109,213,250,0.5), rgba(255,255,255,0));
}