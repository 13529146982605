.forgotPassword {
    position: absolute;
    bottom: 5px;
    right: 25px;
}

.forgotPassword :hover {
    cursor: pointer;
    text-decoration: underline;

}

.otherLogins {
    padding: 0px 10px 20px 10px;
}

.otherLogins > div {
    padding: 0px 10px 0px 10px;
}