.icon {
    margin: 6px;
    transform: translate(0, -3px);
    color: white;
}

.icon :hover {
    cursor: pointer;
    color: purple;
}

.icon-inv {
    margin: 6px;
    transform: translate(0, -3px);
    color: black;
}

.icon-inv :hover {
    cursor: pointer;
    color: purple;
}

.youtube :hover {
    color: #ff0000;
}

.facebook :hover {
    color: #3b5998;
}

.twitter :hover {
    color: #38A1F3;
}

.virbela {
    margin-left: 35px;
}

.footer {
    background: linear-gradient(180deg, #2781FF, #5A98E2);
}

.footerElements {
    position: absolute;
    top: 25%;
    width: 95%;
}

.footerElements a {
    color: white;
    text-decoration: underline;
}

.footerElements a:hover {
    color: white;
}

.justify {
    justify-content: center;
    align-items: center;
}

.cookie {
    text-decoration: underline;
    cursor: pointer;
}